<template>
    <div class="Fyindex"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="系统正在初始化"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.6)"
    style="height:100%"
    >
    <div class="Fyindex-conitaner"
     :style="scaleHeight"
     ref="bigbox"
    >
    <div class="maskbok" v-if = "maskbok"></div>
    <div class="title"> {{ projectData.project }}反应釜醋化流程</div>
        <div class="Fyindex-top flex">
            <div class="Fyindex-top-left flex align-cen">
                <div class="Fyindex-top-left-grid1 flex">
                  <div class="left-grid1">
                      <div class="grid1-num" 
                      @click="toCompon(2, 'WT01', 'DB1C', 'WT01','V101a醋酐储罐重量')"
                      @dblclick="Cclick(infoList.DB1C.WT01,'WT01', 'DB1C', 'DB1C\WT01')"
                      >
                      {{infoList.DB1C.WT01 || 0}}kg
                      </div>
                    <div class="grid1-bg">
                    <div class="bg-text">醋酐</div>
                    <div class="bg-text bg-text1">V101a</div>
                    </div>
                    <div class="grid1-line">
                        <img :src="infoList.DB1C.TV09 | typeFifter" alt="" class="grid1-line-gd">
                        <i class="iconfont icon-al-triangle-fill"></i>
                    </div>
                  </div>
                   <div class="left-grid1 left-grid2">
                      <div class="grid1-num"
                        @click="toCompon(2, 'WT02', 'DB1C', 'WT02','V101b醋酐储罐重量')"
                      @dblclick="Cclick(infoList.DB1C.WT02,'WT02', 'DB1C', 'WT02')"
                      >
                        {{infoList.DB1C.WT02 || 0}}kg
                        </div>
                    <div class="grid1-bg">
                    <div class="bg-text">醋酐</div>
                    <div class="bg-text bg-text1">V101b</div>
                    </div>
                    <div class="grid1-line">
                         <img :src="infoList.DB1C.TV10 | typeFifter" alt="" class="grid1-line-gd">
                        <i class="iconfont icon-al-triangle-fill"></i>
                    </div>
                  </div>
                </div>
                 <div class="Fyindex-top-left-grid2">
                    <div class="left-grid2-tit">
                        温度变化速率
                    </div>
                    <div class="left-grid2-num flex">
                        <span
                    @click="toCompon(2, 'AN07', 'DB1V', 'AN07','A釜温升速率')"
                      @dblclick="Cclick(infoList.DB1V.AN07,'AN07', 'DB1V', 'AN07')"
                        > {{infoList.DB1V.AN07 || '0.0'}}
                        </span>
                        <span
                         @click="toCompon(2, 'AN08', 'DB1V', 'AN08','B釜温升速率')"
                      @dblclick="Cclick(infoList.DB1V.AN08,'AN08', 'DB1V', 'AN08')"
                        >
                           {{infoList.DB1V.AN08 || '0.0'}}
                        </span>
                        <span
                         @click="toCompon(2, 'AN09', 'DB1V', 'AN09','C釜温升速率')"
                         @dblclick="Cclick(infoList.DB1V.AN09,'AN09', 'DB1V', 'AN09')"
                        >
                             {{infoList.DB1V.AN09 || '0.0'}}
                        </span>
                    </div>
                     <div class="left-grid2-num flex">
                        <span
                         @click="toCompon(2, 'AN10', 'DB1V', 'AN10','D釜温升速率')"
                         @dblclick="Cclick(infoList.DB1V.AN10,'AN10', 'DB1V', 'AN10')"
                        >
                             {{infoList.DB1V.AN10 || '0.0'}}
                        </span>
                        <span
                         @click="toCompon(2, 'AN11', 'DB1V', 'AN11','E釜温升速率')"
                         @dblclick="Cclick(infoList.DB1V.AN11,'AN11', 'DB1V', 'AN11')"
                        >
                            {{infoList.DB1V.AN11 || '0.0'}}
                        </span>
                        <span
                         @click="toCompon(2, 'AN12', 'DB1V', 'AN12','F釜温升速率')"
                         @dblclick="Cclick(infoList.DB1V.AN12,'AN12', 'DB1V', 'AN12')"
                        >
                             {{infoList.DB1V.AN12 || '0.0'}}
                        </span>
                    </div>
                 </div>
                       <div class="Fyindex-top-left-grid3 Fyindex-top-left-grid1 flex">
                  <div class="left-grid1">
                      <div class="grid1-num"
                     @click="toCompon(2, 'WT03', 'DB1C', 'WT03','V103a母液储罐重量')"
                      @dblclick="Cclick(infoList.DB1C.WT03,'WT03', 'DB1C', 'WT03')"
                      >
                         {{infoList.DB1C.WT03 || 0}}kg
                      </div>
                    <div class="grid1-bg">
                    <div class="bg-text">母液</div>
                    <div class="bg-text bg-text1">V103a</div>
                    </div>
                    <div class="grid1-line">
                       <img :src="infoList.DB1C.TV11 | typeFifter" alt="" class="grid1-line-gd">
                        <i class="iconfont icon-al-triangle-fill"></i>
                    </div>
                  </div>
                   <div class="left-grid1 left-grid2">
                      <div class="grid1-num"
                       @click="toCompon(2, 'WT04', 'DB1C', 'WT04','V103b母液储罐重量')"
                      @dblclick="Cclick(infoList.DB1C.WT04,'WT04', 'DB1C', 'WT04')"
                      >
                         {{infoList.DB1C.WT04 || 0}}kg
                      </div>
                    <div class="grid1-bg">
                    <div class="bg-text">母液</div>
                    <div class="bg-text bg-text1">V1013b</div>
                    </div>
                    <div class="grid1-line">
                       <img :src="infoList.DB1C.TV12 | typeFifter" alt="" class="grid1-line-gd">
                        <i class="iconfont icon-al-triangle-fill"></i>
                    </div>
                  </div>
                </div>
            </div>
            <div class="Fyindex-top-right flex">
                <div class="Fyindex-top-right-tab">
                    <div class="tab-tit">公用工程</div>
                    <div class="tab-th flex">
                        <div class="tab-th-cell tab-th-cell1">循环水</div>
                        <div class="tab-th-cell">低温水</div>
                    </div>
                    <div class="tab-td flex">
                        <div class="tab-td-cell tab-th-cell1 flex">
                            <div class="tab-td-cell-1">
                                供水温度
                            </div>
                            <div class="tab-td-cell-1 tab-td-cell-2"
                             @click="toCompon(2, 'TE02', 'DB1C', 'TE02','循环水供水温度')"
                             @dblclick="Cclick(infoList.DB1C.TE02,'TE02', 'DB1C', 'TE02')"
                            >
                                {{infoList.DB1C.TE02}}℃
                            </div>
                        </div>
                        <div class="tab-td-cell flex">
                             <div class="tab-td-cell-1">
                                供水温度
                            </div>
                            <div class="tab-td-cell-2"
                             @click="toCompon(2, 'TE04', 'DB1C', 'TE04','低温水供水温度')"
                             @dblclick="Cclick(infoList.DB1C.TE04,'TE04', 'DB1C', 'TE04')"
                            >
                                 {{infoList.DB1C.TE04}}℃
                            </div>
                        </div>
                    </div>
                    <div class="tab-td flex">
                        <div class="tab-td-cell tab-th-cell1 flex">
                            <div class="tab-td-cell-1">
                             回水温度
                            </div>
                            <div class="tab-td-cell-1 tab-td-cell-2"
                             @click="toCompon(2, 'TE03', 'DB1C', 'TE03','循环水回水温度')"
                             @dblclick="Cclick(infoList.DB1C.TE03,'TE03', 'DB1C', 'TE03')"
                            >
                                {{infoList.DB1C.TE03}}℃
                            </div>
                        </div>
                        <div class="tab-td-cell flex">
                             <div class="tab-td-cell-1">
                                回水温度
                            </div>
                            <div class="tab-td-cell-2"
                             @click="toCompon(2, 'TE05', 'DB1C', 'TE05','低温水回水温度')"
                             @dblclick="Cclick(infoList.DB1C.TE05,'TE05', 'DB1C', 'TE05')"
                            >
                                {{infoList.DB1C.TE05}}℃
                            </div>
                        </div>
                    </div>
                    <div class="tab-td flex">
                        <div class="tab-td-cell tab-th-cell1 flex">
                            <div class="tab-td-cell-1">
                               流量
                            </div>
                            <div class="tab-td-cell-1 tab-td-cell-2"
                            @click="toCompon(2, 'FT01', 'DB1C', 'FT01','循环水流量')"
                             @dblclick="Cclick(infoList.DB1C.FT01,'FT01', 'DB1C', 'FT01')"
                            >
                               {{infoList.DB1C.FT01}}  m³/h
                            </div>
                        </div>
                        <div class="tab-td-cell flex">
                             <div class="tab-td-cell-1">
                                 流量
                            </div>
                            <div class="tab-td-cell-2"
                             @click="toCompon(2, 'FT02', 'DB1C', 'FT02','低温水流量')"
                             @dblclick="Cclick(infoList.DB1C.FT02,'FT02', 'DB1C', 'FT02')"
                            >
                                  {{infoList.DB1C.FT02}}  m³/h
                            </div>
                        </div>
                    </div>
                    <div class="tab-td flex">
                        <div class="tab-td-cell tab-th-cell1 flex">
                            <div class="tab-td-cell-1">
                                压力
                            </div>
                            <div class="tab-td-cell-1 tab-td-cell-2"
                             @click="toCompon(2, 'PT02', 'DB1C', 'PT02','循环水压力')"
                             @dblclick="Cclick(infoList.DB1C.PT02,'PT02', 'DB1C', 'PT02')"
                            >
                               {{infoList.DB1C.PT02}} Mpa"
                            </div>
                        </div>
                        <div class="tab-td-cell flex">
                             <div class="tab-td-cell-1">
                                压力
                            </div>
                            <div class="tab-td-cell-2"
                             @click="toCompon(2, 'PT03', 'DB1C', 'PT03','低温水压力')"
                             @dblclick="Cclick(infoList.DB1C.PT03,'PT03', 'DB1C', 'PT03')"
                            >
                              {{infoList.DB1C.PT03}} Mpa"
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Fyindex-bot flex">
            <div class="Fyindex-bot-grid flex"
            v-for="(item,index) in itemList"
            :key="item.id"
            >
                <div class="bot-grid-1">
                    <div class="bot-grid-1-1 flex align-cen">
                        <div class="grid-1-1-text">空气</div>
                         <i class="iconfont icon-al-triangle-fill"></i>
                         <img :src="infoList[item.gdNodeName][item.leftGd1name] | typeFifter" alt="">
                        <div class="grid-1-1-line"> </div>
                        <!-- <div class="grid-1-1-line1"></div> -->
                    </div>
                    <div class="bot-grid-1-1 bot-grid-1-2 flex align-cen">
                        <div class="grid-1-1-text">回水</div>
                         <i class="iconfont icon-al-triangle-fill"></i>
                          <img :src="infoList[item.gdNodeName][item.leftGd2name] | typeFifter" alt="">
                        <div class="grid-1-1-line"> </div>
                    </div>
                      <div class="bot-grid-1-1 bot-grid-1-3 flex align-cen">
                        <div class="grid-1-1-text"></div>
                         <i class="iconfont icon-al-triangle-fill"></i>
                         <img :src="infoList[item.gdNodeName][item.leftGd3name] | typeFifter" alt="">
                        <div class="grid-1-1-line"> </div>
                        <div class="bot-grid-1-1-btn flex align-cen">
                        <div class="child-databtn"
                        :style="infoList[`DB${item.id}M__p__XK02`]  | bgFifter" 
                        	@click="infoList[`DB${item.id}M__p__XK02`] ? toDetail(1,'XK02',`DB${item.id}M__p__XK02`,'',`${item.dot1Name}釜XK02运行方式`)
									: ''"
                        >X</div>
                         <div class="child-databtn"
                         :style="infoList[`DB${item.id}M__p__MAN02`] | bgFifter" 
                         @click="infoList[`DB${item.id}M__p__MAN02`] ? toCompon( 0,'AV',`DB${item.id}M__p__MAN02`,'MAN02_AV',`${item.dot1Name}釜循环水阀手操器`): ''"
                         >A</div>
                    </div>
                    </div>
                     <div class="bot-grid-1-1 bot-grid-1-2 bot-grid-1-4 flex align-cen">
                        <div class="grid-1-1-text"></div>
                         <i class="iconfont icon-al-triangle-fill"></i>
                          <img :src="infoList[item.gdNodeName][item.leftGd4name] | typeFifter" alt="">
                           <img src="../../../assets/images/jflimg2.png" alt="" class="bot-grid-1-4-img">
                        <div class="grid-1-1-line"> </div>
                    </div>
                    <div class="bot-grid-1-5 flex align-cen">
                        <div class="grid-1-5-text">循环水进水</div>
                           <div class="grid-1-5-text1 flex align-cen">
                            <p
                             @click="toCompon(2, 'VF02', `DB${item.id}S`, 'VF02','循环水阀开度反馈')"
                             @dblclick="Cclick(infoList[`DB${item.id}S`]['VF02'],'VF02', `DB${item.id}S`, 'VF02')"
                            >
                                {{infoList[`DB${item.id}S`]['VF02']}}%
                            </p>
                            <p style="font-size:.7vw;padding-left:1vw"
                             @click="toCompon(2, item.dot1, 'DB1V', item.dot1,`${item.dot1Name}釜循环水阀门累积`)"
                             @dblclick="Cclick(infoList['DB1V'][item.dot1],item.dot1, 'DB1V', item.dot1)"
                            >
                                {{infoList['DB1V'][item.dot1]}}
                            </p>
                           </div>
                    </div>
                </div>
                <div class="bot-grid-2">
                    <div class="bot-grid-2-1 flex">
                        <div class="grid-2-1-l">
                        <i class="iconfont icon-al-triangle-fill"></i>
                         <img :src="infoList.DB1C[item.cenGd1name] | typeFifter" alt="">
                        </div>
                        <div class="grid-2-1-r">
                             <i class="iconfont icon-al-triangle-fill"></i>
                         <img :src="infoList.DB1C[item.cenGd2name] | typeFifter" alt="">
                        </div>
                        <div class="grid-2-1-r1">
                              <i class="iconfont icon-al-triangle-fill"></i>
                              <p>水杨酸</p>
                        </div>
                    </div>
                    <div class="bot-grid-2-2">
                        <div class="grid-2-2-t"
                          @click="toCompon(2, 'TE01', `DB${item.id}C`, 'TE01',`${item.dot1Name}釜温度`)"
                          @dblclick="Cclick(infoList[`DB${item.id}C`]['TE01'],'TE01', `DB${item.id}C`, 'TE01')"
                        >
                            {{infoList[`DB${item.id}C`]['TE01']}} ℃
                        </div>
                        <div class="grid-2-2-c">
                           {{item.bsctit}}
                        </div>
                        <div class="grid-2-2-b">
                          {{item.name}}
                        </div>
                    </div>
                </div>
                <div class="bot-grid-3">
                    <div class="bot-grid-3-1 flex align-cen">
                         <div class="child-databtn"
                        :style="infoList[`DB${item.id}M__p__XK01`]  | bgFifter" 
                        	@click="infoList[`DB${item.id}M__p__XK01`] ? toDetail(1,'XK01',`DB${item.id}M__p__XK01`,'',`${item.dot1Name}釜XK01运行方式`)
									: ''"
                        >X</div>
                      <div class="child-databtn"
                         :style="infoList[`DB${item.id}M__p__MAN01`] | bgFifter" 
                         @click="infoList[`DB${item.id}M__p__MAN01`] ? toCompon( 0,'AV',`DB${item.id}M__p__MAN01`,'MAN01_AV',`${item.dot1Name}釜蒸汽阀手操器`): ''"
                         >A</div>
                    </div>
                    <div class="bot-grid-3-2 flex align-cen">
                          <img src="../../../assets/images/jflimg2.png" alt="">
                           <img :src="infoList[`DB${item.id}C`]['TV05'] | typeFifter" alt="" class="bot-grid-1-4-img">
                           <i class="iconfont icon-al-triangle-fill"></i>
                        <div class="grid-3-2-line"></div>
                          <div class="grid-3-2-text">蒸汽</div>
                    </div>
                    <div class="bot-grid-3-3">
                        <div class="grid-3-3-num"
                         @click="toCompon(2, 'VF01', `DB${item.id}S`, 'VF01',`${item.dot1Name}釜蒸汽阀开度反馈`)"
                         @dblclick="Cclick(infoList[`DB${item.id}S`]['VF01'],'VF01', `DB${item.id}S`, 'VF01')"
                        > 
                            {{infoList[`DB${item.id}S`]['VF01']}} ℃
                             </div>
                         <div class="grid-3-3-num">夹套压力</div>
                        <div class="grid-3-3-num"
                          @click="toCompon(2, 'PT01', `DB${item.id}C`, 'PT01',`${item.dot1Name}釜夹套压力`)"
                          @dblclick="Cclick(infoList[`DB${item.id}C`]['PT01'],'PT01', `DB${item.id}C`, 'PT01')"
                        >
                           {{infoList[`DB${item.id}C`]['PT01']}}  Mpa
                             </div>
                    </div>
                    <div class="bot-grid-3-2 bot-grid-3-4 flex align-cen">
                           <img :src="infoList[`DB${item.id}C`]['TV06'] | typeFifter" alt="" class="bot-grid-1-4-img">
                           <i class="iconfont icon-al-triangle-fill"></i>
                        <div class="grid-3-2-line"></div>
                          <div class="grid-3-2-text">冷凝水</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
	  :chName="chName" 
      :infoList="infoList"
    ></Historical>
    <Manual :key="isIndex" v-if="isMshow" @sendStatus="isClose" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList"></Manual>
    </div>
</template>
<script>
import index from "./_index/index.js";
import Historical from "@/components/Historical.vue"; //历史趋势
import Manual from "@/components/Manual.vue"; //手操器组件
export default {
    name:'Fyindex',
    components: {
        Historical,
        Manual
    },
     props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data() {
        return {
            isMshow:false,
            Manualname: "",
            Manualnode: "",
            ManualAname: "",
            Manualtitname: "",
            isHshow:false,
            historyname:'',
            nodename:'',
            Aname:'',
            chName:'',
            fullscreenLoading:false,
            maskbok:false,
            scaleHeight:'',
            spotArr:[],
            flag: 0,
            projectData:[],
            itemList:[
                {
                    id:1,
                    bscd:'',
                    bsctit:'R107a',
                    name:'A釜趋势',
                    dot1:'AN02',
                    dot1Name:'A',
                    leftGd1name:'TV04',
                    leftGd2name:'TV03',
                    leftGd3name:'TV02',
                    leftGd4name:'TV01',
                    cenGd1name:'TV07',
                    cenGd2name:'TV08',
                    gdNodeName:'DB1C',
                    manNode1:'DB1M__p__XK01',
                    manNodeName1:'MAN01_AV',
                    manNodeTitName1:'手操器01状态',
                    XkNode1:'DB1M__p__XK01',
                    XkNodeName1:'XK01',
                    XkNodeTitName1:'XK01运行方式',
                    manNode2:'DB1M__p__XK02',
                    manNodeName2:'MAN02_AV',
                    manNodeTitName2:'手操器02状态',
                    XkNode2:'DB1M__p__XK02',
                    XkNodeName2:'XK02',
                    XkNodeTitName2:'XK02运行方式',
                    

                },{
                    id:2,
                    bscd:'',
                    bsctit:'R107b',
                    name:'B釜趋势',
                    gdNodeName:'DB2C',
                    dot1:'AN04',
                    dot1Name:'B',
                },{
                    id:3,
                    bscd:'',
                    bsctit:'R107c',
                    name:'C釜趋势',
                    gdNodeName:'DB3C',
                    dot1:'AN06',
                    dot1Name:'C',
                },{
                    id:4,
                    bscd:'',
                    bsctit:'R107d',
                    name:'D釜趋势',
                    gdNodeName:'DB4C',
                    dot1:'AN020',
                    dot1Name:'D',
                },
                 {
                    id:5,
                    bscd:'',
                    bsctit:'R107e',
                    name:'E釜趋势',
                    gdNodeName:'DB5C',
                    dot1:'AN22',
                    dot1Name:'E',
                }, {
                    id:6,
                    bscd:'',
                    bsctit:'R107f',
                    name:'F釜趋势',
                    gdNodeName:'DB6C',
                    dot1:'AN24',
                    dot1Name:'F',
                }
            ]
        }
    },
    mixins: [index],
       watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
    created() {
        this.projectData = JSON.parse(localStorage.getItem("deviceType"));
        console.log(this.infoList)
            this.$bus.$on("sendMsg", (msg,msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
           switch(this.flag){
        case 0:
		  return this.JRLcysz = true,this.$router.push({ path: '/Fyindex' })
        case 1:  
            return this.$router.push({ path: '/FyYjqt' })
          
       }
    });

    },
    methods:{
        			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
    },
     filters: {
			// 背景过滤器
			bgFifter: (val) => {
				if (val && val['RM'] == 1) {
					return 'background:#2AFC30';
				} else{
                    return 'background:red';
                }
			},
            	// 管道过滤器
			typeFifter: (val) => {
				if (val == 1) {
					return require("../../../assets/images/rts_bigimg3_end.png");
				} else{
					return require("../../../assets/images/rts_bigimg3_start.png");
				}
			},
		},
}
</script>
<style lang="scss" scoped>
@import "./_index/index.scss";
</style>